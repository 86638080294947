import axios from "axios";
import React, { useState } from "react";
import { BASE_URL } from "../util/axios/axios";
import { toast } from "react-toastify";

const ContactUs = () => {
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [yourMessage, setYourMessage] = useState("");

  const submitContact = (event) => {
    event.preventDefault();

    const payload = {
      first_name: firstName,
      middle_name: middleName,
      last_name: lastName,
      email: email,
      company_name: companyName,
      phone: contactNumber,
      message: yourMessage,
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${BASE_URL}contact-us/`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: payload,
    };

    axios
      .request(config)
      .then((response) => {
        setFirstName("");
        setMiddleName("");
        setLastName("");
        setEmail("");
        setCompanyName("");
        setContactNumber("");
        setYourMessage("");

        toast.success("Message recorded succesfully!");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Contact failed!");
      });
  };

  return (
    <div className="relative min-h-[80vh] font-poppins">
      <div className="background bg-[#FEF8F0]">
        <div className="absolute left-[-30vw] top-[0vh] h-[110vh] w-[60vw] bg-cover rounded-full overflow-hidden border-2 border-[#FFCD12] bg-[#FEF8F0] -z-10">
          <div className="absolute w-full"></div>
        </div>
        <hr className="absolute top-[38vh] w-full border-[1.5px] border-[#FFCD12] overflow-hidden -z-[11]" />
        <div className="w-screen h-screen bg-[#FEF8F0] -z-20 absolute" />
      </div>
      <div className="max-w-screen-2xl mx-auto px-6 sm:px-10 z-20 pt-32">
        <h1 className="font-semibold text-[48px] lg:text-[80px]">Contact Us</h1>
        <span className="font-light text-[20px] text-[#000000]/50">
          Feel free to contact us with any queries
        </span>

        <div className="relative z-20 grid grid-cols-1 md:grid-cols-[75%_25%] gap-4 pt-10 md:pb-20 pb-8">
          <div className="bg-[#FEF5E5] rounded-xl drop-shadow-lg">
            <form
              action=""
              onSubmit={submitContact}
              className=" p-5 sm:p-10 grid md:gird-cols-2 lg:grid-cols-3 gap-4 text-[16px]"
            >
              <div className="relative z-0 w-full mb-8 group">
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  className="block py-2.5 px-4 w-full bg-[#000206]/5 focus:outline-none focus:ring-0 peer rounded-md"
                  placeholder=""
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
                <label
                  htmlFor="firstName"
                  className="absolute text-gray-500 transform translate-x-2 -translate-y-8 scale-85 top-3 -z-10 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0 transition-all duration-300"
                >
                  First Name *
                </label>
              </div>
              <div className="relative z-0 w-full mb-8 group">
                <input
                  type="text"
                  name="middleName"
                  id="middleName"
                  className="block py-2.5 px-4 w-full bg-[#000206]/5 focus:outline-none focus:ring-0 peer rounded-md"
                  placeholder=""
                  value={middleName}
                  onChange={(e) => setMiddleName(e.target.value)}
                />
                <label
                  htmlFor="middleName"
                  className="absolute text-gray-500 transform translate-x-2 -translate-y-8 scale-85 top-3 -z-10 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0 transition-all duration-300"
                >
                  Middle Name (if any)
                </label>
              </div>
              <div className="relative z-0 w-full mb-8 group">
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  className="block py-2.5 px-4 w-full bg-[#000206]/5 focus:outline-none focus:ring-0 peer rounded-md"
                  placeholder=""
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
                <label
                  htmlFor="lastName"
                  className="absolute text-gray-500 transform translate-x-2 -translate-y-8 scale-85 top-3 -z-10 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0 transition-all duration-300"
                >
                  Last Name *
                </label>
              </div>
              <div className="relative z-0 w-full mb-8 group">
                <input
                  type="text"
                  name="companyName"
                  id="companyName"
                  className="block py-2.5 px-4 w-full bg-[#000206]/5 focus:outline-none focus:ring-0 peer rounded-md"
                  placeholder=""
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  required
                />
                <label
                  htmlFor="companyName"
                  className="absolute text-gray-500 transform translate-x-2 -translate-y-8 scale-85 top-3 -z-10 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0 transition-all duration-300"
                >
                  Company Name *
                </label>
              </div>
              <div className="relative z-0 w-full mb-8 group">
                <input
                  type="email"
                  name="emailAddress"
                  id="emailAddress"
                  className="block py-2.5 px-4 w-full bg-[#000206]/5 focus:outline-none focus:ring-0 peer rounded-md"
                  placeholder=""
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <label
                  htmlFor="emailAddress"
                  className="absolute text-gray-500 transform translate-x-2 -translate-y-8 scale-85 top-3 -z-10 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0 transition-all duration-300"
                >
                  Email Address *
                </label>
              </div>
              <div className="relative z-0 w-full mb-8 group">
                <input
                  type="tel"
                  name="contactNo"
                  id="contactNo"
                  className="block py-2.5 px-4 w-full bg-[#000206]/5 focus:outline-none focus:ring-0 peer rounded-md"
                  placeholder=""
                  value={contactNumber}
                  onChange={(e) => setContactNumber(e.target.value)}
                  required
                />
                <label
                  htmlFor="contactNo"
                  className="absolute text-gray-500 transform translate-x-2 -translate-y-8 scale-85 top-3 -z-10 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0 transition-all duration-300"
                >
                  Contact No. *
                </label>
              </div>
              <div className="relative z-0 w-full mb-8 group md:col-span-2 lg:col-span-3">
                <textarea
                  name="yourMessage"
                  id="yourMessage"
                  className="block py-2.5 px-4 w-full bg-[#000206]/5 focus:outline-none focus:ring-0 peer rounded-md"
                  placeholder=""
                  value={yourMessage}
                  onChange={(e) => setYourMessage(e.target.value)}
                />
                <label
                  htmlFor="yourMessage"
                  className="absolute text-gray-500 transform translate-x-2 -translate-y-8 scale-85 top-3 -z-10 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0 transition-all duration-300"
                >
                  Your Message
                </label>
              </div>

              <button type="submit" className="linkage-primary cursor-pointer lg:col-span-3">
                <span className="button_top">Submit</span>
              </button>
            </form>
          </div>
          <div className="drop-shadow-lg">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3532.444999866593!2d85.27889627531748!3d27.70354352565951!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19462d506011%3A0xe4267eef518fe78c!2sSewa%20Printing%20Press%20(Bafal%20Sitapaila%20Office)!5e0!3m2!1sen!2snp!4v1721290134319!5m2!1sen!2snp"
              className="md:h-full h-[35vh] w-full rounded-xl"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              title="sewa print map"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
