import React from "react";
import "../styles/slides.css";

const Clients = () => {
  let banks = [
    "EBL_LOGO.png",
    "Himalayan everest insurance.png",
    "Kumari-Bank-Limited.jpg",
    "nchl.jpeg",
    "prabhumahalaxmi.png",
    "siddhartha-premier-insurance.jpeg",
    "siddhartha.png",
  ];

  let ngos = [
    "equalaccess.png",
    "ICRC logo card.png",
    "ifes.png",
    "peeda.png",
    "unfpa-logo.png",
    "VOW-Media.png",
    "undp.webp",
    "pin.webp",
    "unesco.png",
    "icimod.png",
    "save the children.png",
    "UNICEF-latest-logo.png",
    "who.png",
    "worldvision.png",
  ];

  let corporates = [
    "agni.png",
    "asian paints.png",
    "JLECC_logo_0812.png",
    "ce.png",
    "dabur.png",
    "emai.png",
    "Golchha-Group-100-Years-of-Trust.png",
    "KU.png",
    "HMH.png",
    "KWS.png",
    "MAW.png",
    "nba.png",
    "phoolprasad.png",
    "prismark.png",
    "pycus_holdings_pvt_ltd_logo.png",
    "rb diamonds.png",
    "roomtoreadlogocolor.png",
    "sab.png",
    "siddhipoly.png",
    "surya nepal.png",
    "Unilever.svg.png",
    "urban foods.png",
    "vb.png",
    "DLF greens.png",
    "Heifer-Nepal-LogoH_CMYK.png",
    "Herveda-Botanicals-Logo2.webp",
    "hotelmulberry.png",
    "Kansai_Nerolac_Paints_logo.svg.png",
    "kasthamandap.png",
    "kedia-main-logo.png",
    "Klab.png",
    "nepalliquors.png",
    "Outreach.png",
    "UND.png",
    "Perfetti_Van_Melle_logo.svg",
    "skylight.png",
    "sujal dairy.png",
    "sujal-foods-official-logo.png",
    "piuro.png",
    "helvetas.png",
    "gorkha brewery.png",
    "Better.png",
    "Daraz-Logo.png",
    "hilife.png",
    "himelectronics.png",
    "KVC.png",
    "vchitra.png",
    "cg.png",
    "Samsung_Brand_in_Nepal.png",
    "GWTN.png",
    "thenepaldistilleries.jpeg",
    "shivam_logo_9.png",
    "shree vairab.jpeg",
    "baltra.png",
    "bottlersnepal.png",
  ];
  // let corporates = [
  //   "agni.png",
  //   "asian paints.png",
  //   "Better.png",
  //   "baltra.png",
  //   "bottlersnepal.png",
  //   "ce.png",
  //   "cg.png",
  //   "dabur.png",
  //   "Daraz-Logo.png",
  //   "DLF greens.png",
  //   "emai.png",
  //   "Golchha-Group-100-Years-of-Trust.png",
  //   "gorkha brewery.png",
  //   "GWTN.png",
  //   "Heifer-Nepal-LogoH_CMYK.png",
  //   "helvetas.png",
  //   "Herveda-Botanicals-Logo2.webp",
  //   "hilife.png",
  //   "himelectronics.png",
  //   "HMH.png",
  //   "hotelmulberry.png",
  //   "JLECC_logo_0812.png",
  //   "Kansai_Nerolac_Paints_logo.svg.png",
  //   "kasthamandap.png",
  //   "kedia-main-logo.png",
  //   "Klab.png",
  //   "KU.png",
  //   "KVC.png",
  //   "KWS.png",
  //   "MAW.png",
  //   "nba.png",
  //   "nepalliquors.png",
  //   "Outreach.png",
  //   "Perfetti_Van_Melle_logo.svg",
  //   "phoolprasad.png",
  //   "piuro.png",
  //   "prismark.png",
  //   "pycus_holdings_pvt_ltd_logo.png",
  //   "rb diamonds.png",
  //   "roomtoreadlogocolor.png",
  //   "sab.png",
  //   "Samsung_Brand_in_Nepal.png",
  //   "shivam_logo_9.png",
  //   "shree vairab.jpeg",
  //   "siddhipoly.png",
  //   "skylight.png",
  //   "sujal dairy.png",
  //   "sujal-foods-official-logo.png",
  //   "surya nepal.png",
  //   "thenepaldistilleries.jpeg",
  //   "UND.png",
  //   "Unilever.svg.png",
  //   "urban foods.png",
  //   "vb.png",
  //   "vchitra.png",
  // ];

  let ads = [
    "echo.png",
    "kritya hub.png",
    "Telejuprakashan.png",
    "nepal traveller.png",
    "pearl pub.png",
    "max_media_pvt_ltd__logo.png",
  ];

  return (
    <div className="bg-[#FEF8F0] font-poppins">
      <div className="max-w-screen-2xl mx-auto md:px-10 px-5 md:py-20 pt-20">
        <h1 className="font-semibold text-[32px] md:text-[48px] text-center sm:text-left pb-10">
          Our Clients
        </h1>

        <h2 className="font-semibold text-[24px] md:text-[32px] text-center sm:text-left pb-10">
          Banks
        </h2>

        <div className="flex flex-wrap gap-x-10 gap-y-8 pb-10">
          {banks.map((bank, index) => (
            <img
              className="object-contain md:max-h-14 max-h-10 sm:grayscale sm:opacity-60 hover:grayscale-0 hover:opacity-100 transition-grayscale transition-opacity duration-150 ease-in-out"
              key={index}
              src={require(`../assets/images/Company logo/bank/${bank}`)}
              alt="bank-logo"
            />
          ))}
        </div>

        <h2 className="font-semibold text-[24px] md:text-[32px] text-center sm:text-left py-10 border-t border-black">
          NGO's and INGO's
        </h2>

        <div className="flex flex-wrap gap-x-10 gap-y-8 pb-10">
          {ngos.map((ngo, index) => (
            <img
              className="object-contain md:max-h-14 max-h-10 sm:grayscale sm:opacity-60 hover:grayscale-0 hover:opacity-100 transition-grayscale transition-opacity duration-150 ease-in-out"
              key={index}
              src={require(`../assets/images/Company logo/ngo/${ngo}`)}
              alt="ngo-logo"
            />
          ))}
        </div>
        <h2 className="font-semibold text-[24px] md:text-[32px] text-center sm:text-left py-10 border-t border-black">
          Corporates
        </h2>

        <div className="flex flex-wrap gap-x-10 gap-y-8 pb-10">
          {corporates.map((corporate, index) => (
            <img
              className="object-contain md:max-h-14 max-h-10 sm:grayscale sm:opacity-60 hover:grayscale-0 hover:opacity-100 transition-grayscale transition-opacity duration-150 ease-in-out"
              key={index}
              src={require(`../assets/images/Company logo/corporates/${corporate}`)}
              alt="corporate-logo"
            />
          ))}
        </div>

        <h2 className="font-semibold text-[24px] md:text-[32px] text-center sm:text-left py-10 border-t border-black">
          Ad Agencies
        </h2>

        <div className="flex flex-wrap gap-x-10 gap-y-8 pb-10">
          {ads.map((ad, index) => (
            <img
              className="object-contain md:max-h-14 max-h-10 sm:grayscale sm:opacity-60 hover:grayscale-0 hover:opacity-100 transition-grayscale transition-opacity duration-150 ease-in-out"
              key={index}
              src={require(`../assets/images/Company logo/adagencies/${ad}`)}
              alt="ad-logo"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Clients;
