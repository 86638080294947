import React from "react";

const Services = () => {
  return (
    <div className="w-full h-auto max-w-screen-2xl mx-auto px-5 sm:px-10  py-10 font-poppins ">
      <div className="md:w-[76vw] mx-auto flex flex-col items-center">
        <h1 className="font-semibold text-[32px] md:text-[48px] text-[#000000]">
          Services
        </h1>
        <span className="md:text-[20px] font-light text-center">
          In step with the technology of our time, our press is adequately
          equipped with the most advance range of printing machinery. Coupled
          with the expertise of a team of skilled operators, we offer a host of
          multi-colour printing services - many of which were till recently
          unheard of in Nepal.
        </span>
        <a href="./services" className=" linkage-primary cursor-pointer mt-4">
          <span className="button_top">Learn More</span>
        </a>
      </div>

      <div className=" grid grid-cols-2 md:grid-cols-6 gap-2 sm:gap-4 my-10">
        <div className="h-[24vw] md:h-[18vw] md:col-span-3 ">
          <img
            src={require("../../assets/images/book-2.jpg")}
            className="w-full h-full object-cover rounded-xl"
            alt=""
          />
        </div>
        <div className="h-[24vw] md:h-[18vw]   md:col-span-2 ">
          <img
            src={require("../../assets/images/hardbound-2.jpg")}
            className="w-full h-full object-cover rounded-xl"
            alt=""
          />
        </div>
        <div className="h-[24vw] md:h-[18vw] ">
          <img
            src={require("../../assets/images/catalog-3.jpg")}
            className="w-full h-full object-cover rounded-xl"
            alt=""
          />
        </div>
        <div className="h-[24vw] md:h-[18vw] ">
          <img
            src={require("../../assets/images/hardbound-1.jpg")}
            className="w-full h-full object-cover rounded-xl"
            alt=""
          />
        </div>
        <div className="h-[24vw] md:h-[18vw] md:col-span-2 ">
          <img
            src={require("../../assets/images/catalog-5.jpg")}
            className="w-full h-full object-cover rounded-xl"
            alt=""
          />
        </div>
        <div className="h-[24vw] md:h-[18vw] md:col-span-3 ">
          <img
            src={require("../../assets/images/magazine-4.jpg")}
            className="w-full h-full object-cover rounded-xl"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Services;
