import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../util/axios/axios";
import useFetch from "../../util/useFetch";

const IndividualProcess = () => {
  const { data: individualProcess } = useFetch(`${BASE_URL}equipment`);
  const { data: processDetail } = useFetch(`${BASE_URL}our-service`);
  const { process } = useParams();
  console.log(processDetail);

  const [mainChoice, setMainChoice] = useState();
  const [selectedChoice, setSelectedChoice] = useState([]);

  useEffect(() => {
    const mainProcessChoice =
      processDetail &&
      processDetail.results.find(
        (indiProcess) => indiProcess.service_category === process
      );

    setMainChoice(mainProcessChoice);

    if (mainProcessChoice) {
      const selectedProcess =
        individualProcess &&
        individualProcess.results.filter(
          (indiProcess) => indiProcess.service === mainProcessChoice.id
        );
        setSelectedChoice(selectedProcess);
    }

  }, [processDetail, process, individualProcess]);

  console.log(selectedChoice);

  if (!mainChoice) {
    return <div>Process details not found.</div>;
  }

  if (!selectedChoice || selectedChoice.length === 0) {
    return <div>No machineries found for this process.</div>;
  }

  return (
    <div className="font-poppins bg-[#FEF8F0]">
      <div className="max-w-screen-2xl mx-auto md:px-10 px-5 py-20">
        <div className="py-10">
          <h1 className="font-semibold text-[48px] text-[#000000]">
            {mainChoice.name}
          </h1>
          <span className="text-[16px] font-light text-justify">
            {mainChoice.description}
          </span>
        </div>
        <div>
          <h2 className="font-semibold text-[25px] text-[#000000]">
            Machineries
          </h2>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-200 rounded-lg overflow-hidden py-6">
              <thead>
                <tr className="bg-[#176CA8] text-white">
                  <th className="py-2 px-4 border-r border-white">Model</th>
                  <th className="py-2 px-4 border-r border-white">
                    No. of Machines
                  </th>
                  <th className="py-2 px-4 border-r border-white">
                    Color Printing
                  </th>
                  <th className="py-2 px-4">Size</th>
                </tr>
              </thead>
              <tbody>
                {selectedChoice.map((machine, index) => (
                  <tr className="bg-gray-200" key={index}>
                    <td className="py-2 px-4 border-r border-white">
                      {machine.name || "-"}
                    </td>
                    <td className="py-2 px-4 border-r border-white text-center">
                      {machine.machinenum || "-"}
                    </td>
                    <td className="py-2 px-4 border-r border-white text-center">
                      {machine.color || "-"}
                    </td>
                    <td className="py-2 px-4 text-center">
                      {machine.size || "-"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4 py-10">
            {selectedChoice.map((machine, index) => (
              <div className="relative rounded-xl w-full h-[40vh]" key={index}>
                <img
                  src={`${machine.images}`}
                  alt={`Machine ${index + 1}`}
                  className="rounded-xl w-full h-full object-cover"
                />
                <span className="w-full absolute bottom-0 text-[#ffffff] py-3 flex justify-center items-center bg-[#383737]/50 rounded-b-xl">
                  {machine.name}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndividualProcess;
