import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/slides.css";
import { BASE_URL } from "../../util/axios/axios";
import useFetch from "../../util/useFetch";

const ServiceProcess = () => {
  const { data: services } = useFetch(`${BASE_URL}our-service`);
  const navigate = useNavigate();

  if (!services || !services.results) {
    return <div>Loading...</div>;
  }

  const handleViewMore = (processCategory) => {
    navigate(`/services/${processCategory}`);
  };

  return (
    <div className="bg-[#FEF6E5] w- relative z-10 border-2 border-[#FFCD12]">
      <div
        id="serviceProcess"
        className="max-w-screen-2xl mx-auto py-auto px-4 md:px-10"
      >
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 h-auto items-start my-[5vh]">
          {services.results.map((selectedProcess, index) => (
            <div
              key={index}
              className="bg-white border-2 border-black rounded-lg shadow-lg p-6 h-full flex flex-col justify-between"
            >
              <h1 className="font-semibold text-[24px] md:text-[28px] text-[#000000] mb-4">
                {selectedProcess.name}
              </h1>
              <p className="text-[14px] md:text-[16px] font-light text-justify mb-6 ">
                {selectedProcess.description}
              </p>
              <button
                onClick={() => handleViewMore(selectedProcess.service_category)}
                className="linkage-primary cursor-pointer mt-4"
              >
                <span className="button_top">View More</span>
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServiceProcess;
