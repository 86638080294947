import React, { useState, useEffect } from "react";
import sampleLanding from "../../assets/images/sample-landing.png";

const ServiceLanding = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth >= 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth >= 1024);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="relative h-screen flex items-center max-w-screen-2xl mx-auto px-10 lg:pl-0">
      {isSmallScreen ? (
        <div
          className="absolute left-[-42vw] top-[0vh] h-[110vh] w-[60vw] bg-cover rounded-full overflow-hidden border-2 border-[#FFCD12] z-0"
          style={{ backgroundImage: `url(${sampleLanding})` }}
        />
      ) : (
        <></>
      )}
  
      {/* <div className="flex-1 h-[60vh] my-auto flex flex-col ml-[35vw]"> */}
      <div
        className={`flex-1 w-[70vw] my-auto flex flex-col ${
          isSmallScreen ? "ml-[24vw]" : "mx-auto"
        }`}
      >
        <span className="font-semibold text-[48px] sm:text-[80px]">
          Our Services
        </span>

        <p className="md:text-[20px] font-light text-justify mt-4 mb-8">
          At Sewa Printing Press, we are dedicated to providing top-notch
          printing services tailored to meet your unique needs. Our extensive
          range of services includes high-quality digital and offset printing,
          ensuring vibrant and precise results for all your projects. From
          eye-catching brochures, business cards, flyers, and posters to custom
          binding, lamination, spot UV, embossing, die-cutting, and window
          patching, we have the expertise to bring your vision to life. Our team
          of skilled professionals is committed to delivering excellence and
          ensuring complete customer satisfaction with every project. We use the
          latest technology and highest-quality materials to guarantee that your
          prints are not only stunning but also durable.
        </p>
        <div className=" flex space-x-10">
          <a href="#serviceProcess" className=" linkage-primary cursor-pointer">
            <span className="button_top">Get Started</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ServiceLanding;
