import React from "react";
import Slider from "react-slick";
import { FaRegStar, FaStar } from "react-icons/fa";
import { MdChevronRight, MdChevronLeft } from "react-icons/md";
import "../../styles/style.css";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="absolute bottom-[-6vh] left-[16vw] md:left-[-31vw] md:bottom-16 lg:-bottom-4 xl:bottom-2 2xl:left-[-24vw] border-2 border-black p-2 bg-[#FEF5E5] text-4xl rounded-2xl cursor-pointer"
      style={{ display: "block" }}
      onClick={onClick}
    >
      <MdChevronRight />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="absolute bottom-[-6vh] md:left-[-40vw] md:bottom-16 lg:-bottom-4 xl:bottom-2 2xl:left-[-32vw] border-2 border-black p-2 bg-[#FFCD12] text-4xl rounded-2xl cursor-pointer"
      style={{ display: "block" }}
      onClick={onClick}
    >
      <MdChevronLeft />
    </div>
  );
}

const Testimonials = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const getStars = (item) => {
    const starsInit = [];
    for (let i = 0; i < item; i++) {
      starsInit.push(<FaStar className="text-[#FFCD12] text-lg" key={i} />);
    }
    for (let i = 0; i < 5 - item; i++) {
      starsInit.push(
        <FaRegStar className="text-[#FFCD12] text-lg" key={i + item} />
      );
    }
    return starsInit;
  };

  let reviews = [
    {
      name: "Mahendra Shrestha",
      review:
        "Hall mark of Sewa printing press work delivery is its heritage that galvanised years of craftmanship, precision in quality and unconditioned service.",
      image: "testimonial-2.jpeg",
      company: "Sujal Foods Pvt. Ltd.",
      position: "Sr. General Manager",
      stars: 4,
    },
    {
      name: "Mr. Arun Kaji Sthapit",
      review:
        "Sewa has been synonymous with impeccable quality for over three decades. Printing is not just a job for them; delivering extraordinary quality through state-of-the art printing systems, careful handling of the process and customer service is ingrained in Sewa’s system exceeding customers’ expectations every time.",
      image: "testimonial-1.png",
      company: "Echo Advertising Agency",
      position: "CEO",
      stars: 4,
    },
  ];
  return (
    <div className="w-full max-w-screen-2xl mx-auto px-5 sm:px-10 font-poppins pt-10 pb-20 md:pb-32">
      <div className="grid grid-cols-1 md:grid-cols-[40%_60%] gap-8">
        <div className="text-center md:text-left">
          <h1 className="font-semibold text-[32px] md:text-[48px] text-[#000000]">
            Our Testimonials
          </h1>
          <p className="text-lg md:text-xl font-light mt-4">
            We offer the best service possible to our clients. Hear what our
            clients and customers have to say regarding the service of Sewa
            Printing Press!
          </p>
        </div>

        <div className="testimonialSlider">
          <Slider {...settings}>
            {reviews.map((review, index) => (
              <div
                key={index}
                className="feedbackSlide rounded-xl border bg-[#FEF5E5] w-[96%]"
              >
                <div className="grid lg:grid-cols-[25%_75%] p-6 md:p-12">
                  <div className="flex flex-col items-center">
                    <img
                      src={require(`../../assets/images/${review.image}`)}
                      alt="testimonial"
                      className="h-32 w-32 rounded-full object-cover"
                    />
                    <span className="font-bold mt-2">{review.name}</span>
                    <div className="flex gap-0.5 mt-2">
                      {getStars(review.stars)}
                    </div>
                  </div>
                  <div className="text-justify mt-4 md:mt-0 p-4">
                    {review.review}
                    <div className="block mt-4 text-right">
                      <span className="font-medium">{review.company}</span>
                      <br />
                      <span className="">{review.position}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
