import React from "react";

const SampleCategorization = ({ category }) => {
  // const samples = [
  //   { id: 1, tag: 'Books', primaryImg: 'book-1.jpg' },
  //   { id: 2, tag: 'Books', primaryImg: 'sample-book-2.png' },
  //   { id: 3, tag: 'Books', primaryImg: 'sample-book-3.png' },
  //   { id: 4, tag: 'Catalogs', primaryImg: 'catalog-1.png' },
  //   { id: 5, tag: 'Magazines', primaryImg: 'sample-magazine-2.jpeg' },
  //   { id: 6, tag: 'Posters', primaryImg: 'sample-poster-1.png' },
  //   { id: 7, tag: 'Posters', primaryImg: 'sample-poster-2.png' },
  // ];

  const samples = [
    { id: 1, tag: "Books", primaryImg: "book-1.jpg" },
    { id: 2, tag: "Books", primaryImg: "book-2.jpg" },
    { id: 3, tag: "Books", primaryImg: "book-3.jpg" },
    { id: 4, tag: "Books", primaryImg: "book-4.jpg" },
    { id: 5, tag: "Books", primaryImg: "book-5.jpg" },
    { id: 6, tag: "Books", primaryImg: "book-6.jpg" },
    { id: 7, tag: "Books", primaryImg: "book-7.jpg" },
    { id: 8, tag: "Books", primaryImg: "book-8.jpg" },
    { id: 9, tag: "Books", primaryImg: "book-9.jpg" },
    { id: 10, tag: "Books", primaryImg: "book-10.jpg" },
    { id: 11, tag: "Books", primaryImg: "book-11.jpg" },
    { id: 12, tag: "Books", primaryImg: "book-12.jpg" },
    { id: 13, tag: "Books", primaryImg: "book-13.jpg" },
    { id: 14, tag: "Books", primaryImg: "book-14.jpg" },
    { id: 15, tag: "Books", primaryImg: "book-15.jpg" },
    { id: 16, tag: "Books", primaryImg: "book-16.jpg" },
    { id: 17, tag: "Books", primaryImg: "book-17.jpg" },
    { id: 18, tag: "Books", primaryImg: "book-18.jpg" },
    { id: 19, tag: "Books", primaryImg: "book-19.jpg" },
    { id: 20, tag: "Books", primaryImg: "book-20.jpg" },
    { id: 21, tag: "Books", primaryImg: "book-21.jpg" },
    { id: 22, tag: "Books", primaryImg: "book-22.jpg" },
    { id: 23, tag: "Books", primaryImg: "book-23.jpg" },
    { id: 24, tag: "Books", primaryImg: "book-24.jpg" },
    { id: 25, tag: "Books", primaryImg: "book-25.jpg" },
    { id: 26, tag: "Books", primaryImg: "book-26.jpg" },
    { id: 27, tag: "Books", primaryImg: "book-27.jpg" },
    { id: 28, tag: "Books", primaryImg: "book-28.jpg" },
    { id: 29, tag: "Books", primaryImg: "book-29.jpg" },
    { id: 30, tag: "Books", primaryImg: "book-30.jpg" },
    { id: 31, tag: "Books", primaryImg: "book-31.jpg" },
    { id: 32, tag: "Books", primaryImg: "book-32.jpg" },
    { id: 33, tag: "Catalogs", primaryImg: "catalog-1.jpg" },
    { id: 34, tag: "Catalogs", primaryImg: "catalog-2.jpg" },
    { id: 35, tag: "Catalogs", primaryImg: "catalog-3.jpg" },
    { id: 36, tag: "Catalogs", primaryImg: "catalog-4.jpg" },
    { id: 37, tag: "Catalogs", primaryImg: "catalog-5.jpg" },
    { id: 38, tag: "Catalogs", primaryImg: "catalog-6.jpg" },
    { id: 39, tag: "Catalogs", primaryImg: "catalog-7.jpg" },
    { id: 40, tag: "Catalogs", primaryImg: "catalog-8.jpg" },
    { id: 41, tag: "Catalogs", primaryImg: "catalog-9.jpg" },
    { id: 42, tag: "Catalogs", primaryImg: "catalog-10.jpg" },
    { id: 43, tag: "Catalogs", primaryImg: "catalog-11.jpg" },
    { id: 44, tag: "Hardbound", primaryImg: "hardbound-1.jpg" },
    { id: 45, tag: "Hardbound", primaryImg: "hardbound-2.jpg" },
    { id: 46, tag: "Hardbound", primaryImg: "hardbound-3.jpg" },
    { id: 47, tag: "Hardbound", primaryImg: "hardbound-4.jpg" },
    { id: 48, tag: "Hardbound", primaryImg: "hardbound-5.jpg" },
    { id: 49, tag: "Hardbound", primaryImg: "hardbound-6.jpg" },
    { id: 50, tag: "Hardbound", primaryImg: "hardbound-7.jpg" },
    { id: 51, tag: "Hardbound", primaryImg: "hardbound-8.jpg" },
    { id: 52, tag: "Magazines", primaryImg: "magazine-1.jpg" },
    { id: 53, tag: "Magazines", primaryImg: "magazine-2.jpg" },
    { id: 54, tag: "Magazines", primaryImg: "magazine-3.jpg" },
    { id: 55, tag: "Magazines", primaryImg: "magazine-4.jpg" },
    { id: 56, tag: "Magazines", primaryImg: "magazine-5.jpg" },
    { id: 57, tag: "Magazines", primaryImg: "magazine-6.jpg" },
    { id: 56, tag: "Storybook", primaryImg: "storybook-1.jpg" },
    { id: 57, tag: "Storybook", primaryImg: "storybook-2.jpg" },
    { id: 58, tag: "Storybook", primaryImg: "storybook-3.jpg" },
  ];

  console.log({ category });

  return (
    <div className="max-w-screen-2xl mx-auto px-5 grid md:grid-cols-4 sm:grid-cols-2 gap-6 pb-10">
      {samples.map((sample, index) => {
        if (category === "All") {
          return (
            <div key={index} className="w-full ">
              <img
                src={require(`../../assets/images/${sample.primaryImg}`)}
                alt={sample.tag}
                className="object-cover rounded-2xl hover:scale-[1.2] duration-300 ease-in-out w-full"
              />
            </div>
          );
        } else if (category === sample.tag) {
          return (
            <div key={index} className="w-full">
              <img
                src={require(`../../assets/images/${sample.primaryImg}`)}
                alt={sample.tag}
                className="object-cover rounded-2xl hover:scale-[1.2] duration-300 ease-in-out w-full"
              />
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

export default SampleCategorization;
