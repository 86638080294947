import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../styles/style.css";

import sampleBook1 from "../../assets/images/book-21.jpg";
import sampleBook2 from "../../assets/images/hardbound-5.jpg";
import sampleBook3 from "../../assets/images/catalog-11.jpg";

const AboutUs = () => {
  const settings = {
    infinite: true,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,

    autoplaySpeed: 3000,
    pauseOnHover: false,
    arrows: false,
    customPaging: function (i) {
      return (
        <a>
          <div className="dot w-[24px] h-[6px] rounded-xl bg-[#FFCD12]"></div>
        </a>
      );
    },
    appendDots: (dots) => (
      <div>
        <ul className="flex justify-center space-x-4">{dots}</ul>
      </div>
    ),
  };

  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1250);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1250);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const content =
    "Established in 1988, Sewa Printing Press is promoted by a group of highly committed, innovative and well experienced persons and managed by a team of young and energetic professionals. Built on four decades of extensive experience in the printing industry, Sewa has been successfully catering toi the multifarous printing needs of clientele that include several of the foremost national and international agencies, projects, government organizations & non-government organizations (NGOs) and advertising agencies among others.";
  const reducedConent =
    "Established in 1988, Sewa Printing Press is promoted by a group of highly committed, innovative and well experienced persons and managed by a team of young and energetic professionals. ";

  return (
    <div className="h-auto lg:h-[70vh]  font-poppins bg-[#FEF6E5] border-b-2 border-[#FFCD12] flex items-center ">
      <div className="w-screen  max-w-screen-2xl mx-auto px-5 sm:px-10 py-10 relative">
        <div className="flex flex-col-reverse md:flex-row lg:gap-20 gap-10 items-center">
          <div className="aboutUsSlider w-[90vw] sm:w-[70vw] md:w-[40vw] mx-auto">
            <Slider {...settings}>
              <div>
                <img
                  src={sampleBook2}
                  alt="Sample Book 2"
                  className="object-cover w-full h-full rounded-xl shadow-lg"
                />
              </div>
              <div>
                <img
                  src={sampleBook1}
                  alt="Sample Book 1"
                  className="object-cover w-full h-full rounded-xl shadow-lg"
                />
              </div>
              <div>
                <img
                  src={sampleBook3}
                  alt="Sample Book 3"
                  className="object-cover w-full h-full rounded-xl shadow-lg"
                />
              </div>
            </Slider>
          </div>
          <div className="mt-8 lg:mt-0">
            <div className="mx-auto text-center md:text-left space-y-6">
              <div className="flex items-center justify-center md:justify-start">
                <div className="bg-[#FFCD12] w-2 h-[3vh] mr-2"></div>
                <span className="font-semibold md:text-[20px] ">
                  Who we are?
                </span>
              </div>
              <h1 className="font-bold text-[32px] md:text-[48px] leading-tight">
                About <span className="text-[#FFCD12]">Us</span>
              </h1>
              <p className=" md:text-xl mt-4 lg:mt-6 leading-relaxed">
                {isLargeScreen ? content : reducedConent}
              </p>
              <a
                href="./contactUs"
                className="linkage-primary mt-6 md:mt-10 block  max-w-xs mx-auto md:mx-0 cursor-pointer"
              >
                <span className="button_top">Contact Us</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
