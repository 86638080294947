import React from "react";

const AboutUsTeam = () => {
  return (
    <div className="bg-[#FEF6E5] md:min-h-screen w-full border-y-2 border-y-[#FFCD12] flex items-center">
      <div className="max-w-screen-2xl mx-auto py-10 px-4 md:px-10 text-center">
        <h1 className="font-bold text-[32px] md:text-[48px] leading-tight">
          The Sewa Team
        </h1>
        <p className="md:text-xl mt-4 lg:mt-6 leading-relaxed text-justify mx-auto max-w-5xl">
          The hardworking team of Sewa press is the best because they deliver
          timely and accurate communication, ensuring information reaches the
          right audience effectively.
        </p>
        <div className="py-8 md:h-[70vh] h-full w-full">
          <img
            src={require("../../assets/images/sewaTeam.png")}
            alt="Sewa team"
            className="object-cover h-full w-full rounded-2xl"
          />
        </div>
      </div>
    </div>
  );
};

export default AboutUsTeam;
