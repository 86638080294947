import React from "react";
import BgVideo from "../assets/video/video.mp4";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import GetAQuote from "../components/popUps/GetAQuote";

import AboutUs from "../components/home/aboutUs";
import Services from "../components/home/services";
import Testimonials from "../components/home/testimonials";
import "../../src/styles/style.css";

const Home = () => {
  const GetAQuotePopUp = withReactContent(Swal);

  const showQuoteFormPopup = () => {
    const updatePopupWidth = () => {
      const isSmallScreen = window.innerWidth <= 768;
      const width = isSmallScreen ? "96%" : "70%";

      const popupContent = document.querySelector(".swal2-popup");
      if (popupContent) {
        popupContent.style.width = width;
      }
    };

    const firePopup = () => {
      GetAQuotePopUp.fire({
        title: "Get A Quote",
        html: <GetAQuote />,
        width: window.innerWidth <= 600 ? "96%" : "70%",
        showConfirmButton: false,
        showCloseButton: true,
        background: "#F5EFE4",
        color: "#000000",
        grow: "column",
        animation: true,
        didOpen: () => {
          // Event listener for window resize
          window.addEventListener("resize", updatePopupWidth);
        },
        willClose: () => {
          // Remove event listener when popup is closed
          window.removeEventListener("resize", updatePopupWidth);
        },
      });
    };

    firePopup(); // Initial call to create the popup
  };

  return (
    <div className="bg-[#FEF8F0]">
      <div className="w-screen h-dvh relative font-poppins">
        <video
          src={BgVideo}
          autoPlay
          loop
          muted
          className="w-full h-full object-cover"
        ></video>
        <div className="absolute inset-0 flex justify-center  md:justify-start items-center">
          <div className="w-[90vw] md:w-[70vw] lg:w-[55vw] bg-[#FEF5E5]/90 rounded-2xl mx-5 md:mx-10 p-5 md:p-10">
            <div className="flex items-center mb-4">
              <div className="bg-[#FFCD12] w-[3px] h-[24px] mr-2"></div>
              <span className="font-semibold text-[18px] md:text-[20px]">
                Sewa Printing Press
              </span>
            </div>
            <h1 className="font-semibold text-[24px] md:text-[32px] lg:text-[48px] text-[#000000] mb-4">
              Professional Printing Services for Your Business
            </h1>
            <span className="text-[14px] md:text-[16px] lg:text-[20px] font-light mb-4 block">
              In step with the technology of our time, our press is adequately
              equipped with the most advance range of printing machinery.
            </span>
            <div className="sm:flex flex-row">
              <a
                className="linkage-primary mr-4 cursor-pointer"
                onClick={showQuoteFormPopup}
              >
                <span className="button_top">Get a Quote</span>
              </a>
              <a href="/services" className="linkage-secondary cursor-pointer">
                <span className="button_top">Learn More</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <AboutUs />
      <Services />
      <Testimonials />
    </div>
  );
};

export default Home;
