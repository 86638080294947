import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import GetAQuote from "../popUps/GetAQuote";
import "../../styles/style.css";
import { IoMenuOutline, IoCloseOutline } from "react-icons/io5";

const Navbar = () => {
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [visible, setVisible] = useState(true);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

  const GetAQuotePopUp = withReactContent(Swal);

  const showQuoteFormPopup = () => {
    const updatePopupWidth = () => {
      const isSmallScreen = window.innerWidth <= 768;
      const width = isSmallScreen ? "96%" : "70%";

      const popupContent = document.querySelector(".swal2-popup");
      if (popupContent) {
        popupContent.style.width = width;
      }
    };

    const firePopup = () => {
      GetAQuotePopUp.fire({
        title: "Get A Quote",
        html: <GetAQuote />,
        width: window.innerWidth <= 768 ? "96%" : "70%",
        showConfirmButton: false,
        showCloseButton: true,
        background: "#F5EFE4",
        color: "#000000",
        grow: "column",
        animation: true,
        didOpen: () => {
          // Event listener for window resize
          window.addEventListener("resize", updatePopupWidth);
        },
        willClose: () => {
          // Remove event listener when popup is closed
          window.removeEventListener("resize", updatePopupWidth);
        },
      });
    };

    firePopup(); // Initial call to create the popup
  };

  const links = [
    { text: "Home", url: "/" },
    { text: "Services", url: "/services" },
    { text: "Clients", url: "/clients" },
    { text: "Contact Us", url: "/contactUs" },
    { text: "About Us", url: "/aboutUs" },
  ];

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      if (!menuOpen) {
        setVisible(scrollTop < lastScrollTop || scrollTop < 100); // Show on scroll up or near top
        setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop); // For Mobile or negative scrolling
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollTop, menuOpen]);

  useEffect(() => {
    const handleResize = () => {
      const isMobileView = window.innerWidth <= 1024;
      setIsMobile(isMobileView);
      if (!isMobileView) {
        setMenuOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <nav
      className={`navbar fixed w-full pl-5 sm:pr-10 pr-5 z-40 bg-[#FEF5E5] font-poppins border-b-2 border-[#FFCD12] transition-transform duration-300 ${
        visible || menuOpen ? "translate-y-0" : "-translate-y-full"
      }`}
    >
      <div className="relative flex items-center justify-between h-20">
        <div
          className={`absolute sm:left-8 flex items-center transition-all duration-300 ${
            visible || menuOpen ? "scale-100" : "scale-75"
          }`}
        >
          <a href="/">
            <img
              className={`${
                window.scrollY >= 20 ? `h-16` : "mt-12 lg:mt-20 h-24 lg:h-32"
              } overflow-auto transition-all duration-300 ease-in-out `}
              src={require("../../assets/images/logo.png")}
              alt="Logo"
            />
          </a>
        </div>
        <div className="flex-1 flex justify-end items-center space-x-10">
          <div
            className={`hidden lg:flex space-x-16 ${
              isMobile ? "hidden" : "flex"
            }`}
          >
            {links.map((link, index) => (
              <a
                key={index}
                href={link.url}
                className={`${
                  location.pathname === link.url
                    ? "border-b-4 border-[#FFCD12] font-semibold"
                    : "hover:text-[#FFCD12] transition-colors duration-300"
                }`}
              >
                {link.text}
              </a>
            ))}
          </div>
          <button
            className={`linkage-secondary ${
              isMobile ? "hidden" : "block"
            } lg:block`}
            onClick={showQuoteFormPopup}
          >
            <span className="button_top">Get a Quote</span>
          </button>
          <button
            onClick={toggleMenu}
            className={`text-4xl block lg:hidden text-black ml-6`}
          >
            {menuOpen ? <IoCloseOutline /> : <IoMenuOutline />}
          </button>
        </div>
      </div>
      {menuOpen && isMobile && (
        <div className="fixed inset-0 h-screen bg-[#FEF5E5] flex flex-col items-center justify-center z-60 overflow-hidden">
          <button
            onClick={toggleMenu}
            className="absolute text-4xl text-black mb-8 right-4 top-4"
          >
            <IoCloseOutline />
          </button>
          {links.map((link, index) => (
            <a
              key={index}
              href={link.url}
              className={`text-2xl py-6 ${
                location.pathname === link.url
                  ? "font-semibold"
                  : "hover:text-[#FFCD12] transition-colors duration-300"
              }`}
              onClick={() => setMenuOpen(false)}
            >
              {link.text}
            </a>
          ))}
          <button
            className="linkage-secondary cursor-pointer md:mt-0 mt-8"
            onClick={showQuoteFormPopup}
          >
            <span className="button_top">Get a Quote</span>
          </button>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
